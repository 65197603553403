'use strict';

if (!('find' in Array.prototype)) {
  Array.prototype.find = function (callback, thisArg) {
    for (var i = 0; i < this.length; i++) {
      if (callback.call(thisArg, this[i], i, this)) {
        return this[i];
      }
    }
    return null;
  };
}

if (!('assign' in Object.prototype)) {
  Object.assign = function(target, varArgs) {
    if (!target) {
      target = {};
    }
    var to = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var nextSource = arguments[index];

      if (nextSource != null) {
        for (var nextKey in nextSource) {
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
    return to;
  };
}
