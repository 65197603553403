require('../../scss/client/styles.scss');

// external


require('../common.js');
require('./rdv.js');

$(function() {

  $('select').each(function() {
    var select = $(this);
    var v = select.data('value');
    if (v && v.split) {
      v.split(',').forEach(function(val) {
        select.find('option[value="' + val + '"]').prop('selected', true);
        select.find('option[value="' + val.toUpperCase() + '"]').prop('selected', true);
      });
    } else if (v) {
      select.find('option[value="' + v + '"]').prop('selected', true);
    }
    select.trigger('change');
  });

  $('input[type=radio]').each(function() {
    var radio = $(this);
    var v = radio.data('value');
    if (v && v === radio.attr('value')) {
      radio.prop('checked', true);
    }
  });

  if ($('#client-screen').length) {
    setInterval(function() {
      var url = window.location.href.split('?')[0] + '?ajax=1';
      $.get(url, function(data) {
        if (data === 'notfound') {
          window.location.href = '/client';
          return;
        }
        $('#client-screen').html(data);
        showQrcodes();
      });
    }, 10000);
  }

  $('li.error').each(function() {
    var param = $(this).data('param');
    if (!param) {
      return;
    }
    $('input#' + param).addClass('is-invalid');
  });

  // force latin characters
  $('form#client-registration-form input, form#rdv-registration-form input').on('input', function(e) {
    var s = $(this).val();
    var replaced = s.replace(/[^\u0000-\u007F\u0080-\u00FF]/g, '');
    if (s !== replaced) {
      $(this).val(replaced);
    }
  });

  // phone code select
  var setPhoneCode = function(select) {
    var code = select.find('option:selected').data('code');
    if (code) {
      $('#phone_number').val(code + ' ');
    }
  };
  $('form #phone_country').change(function() {
    setPhoneCode($(this));
  });
  if (!$('#phone_number').val()) {
    setPhoneCode($('#phone_country'));
  }

  // cookies policy
  if (window.Cookies.get('policy') !==  'accepted') {
    // show policy
    $('.cookies-policy').prependTo('body');
    $('.cookies-policy').show();
    // and set cookie
    window.Cookies.set('policy', 'accepted', { expires : 395, sameSite: 'Lax' }); // 13 months
  }

  // form validation
  if ($('#client-registration-form').length > 0) {
    window.onSubmit = function() {
      document.getElementById('client-registration-form').submit();
    };
  }

});
