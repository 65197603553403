if (!window.console) window.console = {};
if (!window.console.log) window.console.log = function () { };

require('./ie-compatibility');

$(function() {
  // qr code
  window.showQrcodes = function() {
    $('#qrcodediv').each(function() {
      var text = $(this).data('text');
      var size = $(this).data('size') || 200;
      var el = kjua({
        text: text,
        size: size
      });
      $(this).append(el);
    });
  };
  showQrcodes();

  //
  $(() => {
    // sort
    $('.table-sort-asc, .table-sort-desc').on('click', function() {
      const sort  = $(this).data('sort');
      const input = $('input[name=sort]');
      input.val(sort);

      input.closest('form').trigger('submit');
    });
  });
});
