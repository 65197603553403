

$(function() { 

  if ($('#rdv-registration-form').length < 1) {
    return;
  }

  // form validation
  window.onSubmit = function() {
    document.getElementById('rdv-registration-form').submit();
  };
  
  var ready = false;

  // datepicker
  var lang = $('html').attr('lang');
  $.datetimepicker.setLocale(lang);

  $('#date').datetimepicker({
    dayOfWeekStart: 1,
    format: 'd/m/Y',
    timepicker: false,
    timepickerScrollbar: false,
    scrollMonth: false,
    scrollInput: false,
    minDate: 0
  });
 
  $('select.select2').select2({
    language: lang,
    width: '100%',
    sorter: data => data.sort((a, b) => {
      if (!a.id) {
        return -1;
      }
      if (!b.id) {
        return 1;
      }
      return a.text.localeCompare(b.text);
    }),
  });

  $('li.error').each(function() {
    var param = $(this).data('param');
    if (!param) {
      return;
    }
    $('input#' + param).addClass('is-invalid');
  });

  var newOption = function(option, selected) {
    selected = !!selected;
    option = $(option);
    var opt = new Option(option.text(), option.attr('value'), selected, selected);
    return $(opt).data($(option).data());
  };

  // toggle slots visibilty, by date and by purpose
  var toggleSlots = function() {
    var date = $('#date').val();
    var purpose = $('#purpose option:selected').val();
    var departments = $('#departments option:selected').toArray();
    var noslots = true;
    $('#slots a.btn-slot').each(function() {
      // dates match
      var datematch = date && date === $(this).data('date');
      if (!datematch) {
        $(this).toggle(false);
        return;
      }

      // capacity for this purpose
      var capacity = $(this).data(purpose);
      // discoverbags
      if (purpose === 'discover') {
        if (departments.length === 0) {
          capacity = capacity || $(this).data('discoverbags');
        } else if ($('#departments option[value=bags]:selected').length > 0) {
          capacity = $(this).data('discoverbags');
        }
      }
      if (!capacity) {
        $(this).toggle(false);
        return;
      }
      $(this).toggle(true);
      noslots = false;
    });
    
    $('#noslots').toggle(noslots);

    // unselect slots
    $('#id_slot').val('');
    $('a.btn-slot.active').removeClass('active');

    // keep previous slot selected
    var ori_id_slot = $('#ori_id_slot').val();
    var slot_btn    = ori_id_slot && $('a.btn-slot[data-id=' + ori_id_slot + ']');
    // keep active btn slot if still visible
    if (ori_id_slot && slot_btn.is(':visible')) {
      $('#id_slot').val(ori_id_slot);
      slot_btn.addClass('active');
    }

    // optional department
    var optionaldept = $('#purpose option:selected').data('optionaldept');
    $('label[for=departments]').toggleClass('required', !optionaldept);
  };

  // refresh slots for store (when store is selected)
  var onStoreChange = function() {
    var id_store = $('#id_store').val();
    if (!id_store) {
      return;
    }
    // filter available purposes
    filterPurposes();
    $.get('/form/slots?id_store=' + id_store, function(html) {
      $('#slots').html(html);
      filterDates();
      toggleSlots();
      ready = true;
    });
  };

  // filter dates according to slots
  var filterDates = function() {
    // update datepicker
    var allowDates = [];
    $('#slots > a').each(function() {
      var date = $(this).data('date');
      if (allowDates.indexOf(date) < 0) {
        allowDates.push(date);
      }
    });
    if (allowDates.length > 0) {
      // update datetimepicker
      $('#date').removeAttr('disabled');
      if (!$('#date').val()) {
        $('#date').val(allowDates[0]);
      }
      $('#date').datetimepicker({ allowDates, formatDate: 'd/m/Y'});
    } else {
      // disable datetimepicker
      $('#date').attr('disabled', 'true');
    }
  };
  
  // filter purposes (when store is selected)
  var purposes = $('#purpose option').toArray();
  var filterPurposes = function(e) {
    var store = $('#id_store option:selected');
    if (!store || !store.data('purposes')) {
      return;
    }
    var types = [];
    var storePurposes = store.data('purposes').split(',');
    $('#purpose option').remove();
    purposes.forEach(function(purpose) {
      if (storePurposes.indexOf($(purpose).attr('value')) > -1) {
        types.push($(purpose).data('type'));
      }
    });

    // show/hide types radios
    var visibleTypes = types.indexOf('store') > -1 && types.indexOf('phone') > -1;
    $('#types').toggle(visibleTypes);
    var type = null;
    if (visibleTypes) {
      type = $('#types input:checked').attr('value');
      if (!type) {
        $('#types input').each(function() {
          if ($(this).attr('value') === $(this).data('value')) {
            type = $(this).attr('value');
          }
        });
      }
    }

    // update purposes select
    var value = $('#purpose').data('value');
    purposes.forEach(function(purpose) {
      if (storePurposes.indexOf($(purpose).attr('value')) > -1 &&
          (!type || type === $(purpose).data('type'))) {
        $('#purpose').append(newOption(purpose, $(purpose).attr('value') === value));
      }
    });
    $('#purpose').trigger('change');
  };

  // filter stores by country
  var stores = $('#id_store option').toArray();
  var onCountryChange = function() {
    var country = $('#id_country option:selected').attr('value');
    $('#id_store option').each(function() {
      if ($(this).attr('value')) {
        $(this).remove();
      }
    });
    stores.forEach(function(store) {
      if (String($(store).data('country')) === country) {
        $('#id_store').append(newOption(store));
      }
    });
    if (ready) {
      onStoreChange();
    }
  };

  // select slot
  $(document).on('click', '.btn-slot', function(e) {
    e.preventDefault();
    $('.btn-slot').removeClass('active');
    $(this).addClass('active');
    $('#id_slot').val($(this).attr('data-id'));
    $('#ori_id_slot').val('');
  });

  $('#id_country').on('change', onCountryChange);
  $('#id_store').on('change', onStoreChange);
  $('#types input[type=radio]').on('change', filterPurposes);
  $('#purpose').on('change', toggleSlots);
  $('#departments').on('change', toggleSlots);
  $('#date').on('change', toggleSlots);

});